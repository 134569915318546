module.exports = {
  default: {
    locale: "en-US",
    url:
      process.env.FRONT_COMMERCE_CLOUD_URL ||
      process.env.FRONT_COMMERCE_URL ||
      "http://localhost:4000",
    currency: "EUR",
    default_country_id: "GB",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
};
