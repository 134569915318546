import React from "react";
import { compose } from "recompose";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import EnhanceRegisterForm from "./EnhanceRegisterForm";
import LoginMutation from "theme/modules/User/LoginForm/LoginMutation.gql";
import RegisterMutation from "./RegisterMutation.gql";
import {
  isPasswordValid,
  errorMessage,
  passwordNotMatchingErrorMessage,
  MIN_PASSWORD_LENGTH,
  MIN_CHAR_CLASSES,
} from "theme/components/atoms/Form/Input/Password/passwordValidation";
import Form from "theme/components/atoms/Form/Form";
import Fieldset from "theme/components/atoms/Form/Fieldset";
import FormActions from "theme/components/molecules/Form/FormActions";
import { Text, Password, Hidden } from "theme/components/atoms/Form/Input";
import Email from "theme/modules/SmartForms/Field/Email";
import FirstName from "theme/modules/SmartForms/Field/FirstName";
import TitleSelect from "theme/components/atoms/Form/Input/TitleSelect";
import { Checkbox } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import { ErrorAlert } from "theme/components/molecules/Alert";
import config from "config/website";
import Stack from "theme/components/atoms/Layout/Stack";

const messages = defineMessages({
  lastnameLabel: {
    id: "modules.User.RegisterForm.lastname",
    defaultMessage: "Lastname",
  },
  lastnameError: {
    id: "modules.User.RegisterForm.lastnameError",
    defaultMessage: "Please fill in your lastname",
  },
  firstnameLabel: {
    id: "modules.User.RegisterForm.firstname",
    defaultMessage: "Firstname",
  },
  firstnameError: {
    id: "modules.User.RegisterForm.firstnameError",
    defaultMessage: "Please fill in your firstname",
  },
  emailLabel: {
    id: "modules.User.RegisterForm.email",
    defaultMessage: "Email",
  },
  emailError: {
    id: "modules.User.RegisterForm.emailError",
    defaultMessage: "Please fill in a valid email",
  },
  passwordLabel: {
    id: "modules.User.RegisterForm.password",
    defaultMessage: "Password",
  },
  passwordConfirmationLabel: {
    id: "modules.User.RegisterForm.passwordConfirm",
    defaultMessage: "Password confirmation",
  },
  newsletterLabel: {
    id: "modules.User.RegisterForm.newsletter",
    defaultMessage: "Subscribe to our Newsletter",
  },
  titleError: {
    id: "modules.User.RegisterForm.titleError",
    defaultMessage: "Please choose your title",
  },
  titleLabel: {
    id: "modules.User.RegisterForm.title",
    defaultMessage: "Title",
  },
});

const RegisterForm = ({ initialValues, ...props }) => {
  return (
    <Form
      onChange={props.onChange}
      onValidSubmit={(data) => {
        props.setPending(true);
        props.register(data);
      }}
    >
      <Fieldset>
        <Stack desktopSize="2" mobileSize="4">
          {props.errorMessage && (
            <ErrorAlert key="error">{props.errorMessage}</ErrorAlert>
          )}
          {initialValues?.orderId && (
            <Hidden name="orderId" id="orderId" value={initialValues.orderId} />
          )}

          <Stack key="form" desktopSize="1" mobileSize="2">
            {config.useUserTitles && (
              <TitleSelect
                placeholder={props.intl.formatMessage(messages.titleLabel)}
                aria-label={props.intl.formatMessage(messages.titleLabel)}
                name="title"
                id="title"
                value={initialValues?.title}
              />
            )}
            <Text
              name="lastname"
              id="lastname"
              required
              value={initialValues?.lastname}
              aria-label={props.intl.formatMessage(messages.lastnameLabel)}
              placeholder={props.intl.formatMessage(messages.lastnameLabel)}
              validationError={props.intl.formatMessage(messages.lastnameError)}
            />
            <FirstName
              name="firstname"
              id="firstname"
              required
              value={initialValues?.firstname}
              aria-label={props.intl.formatMessage(messages.firstnameLabel)}
              placeholder={props.intl.formatMessage(messages.firstnameLabel)}
              validationError={props.intl.formatMessage(
                messages.firstnameError
              )}
            />
            <Email
              name="email"
              id="email"
              required
              aria-label={props.intl.formatMessage(messages.emailLabel)}
              placeholder={props.intl.formatMessage(messages.emailLabel)}
              validationError={props.intl.formatMessage(messages.emailError)}
              autoComplete="username"
              value={props.value?.email ?? initialValues?.email}
            />
            <Password
              name="password"
              id="password"
              autoComplete="new-password"
              required
              aria-label={props.intl.formatMessage(messages.passwordLabel)}
              placeholder={props.intl.formatMessage(messages.passwordLabel)}
              validations={{
                magentoPasswordRule: (_, value) => isPasswordValid(value),
              }}
              validationError={props.intl.formatMessage(errorMessage, {
                minLength: MIN_PASSWORD_LENGTH,
                minClasses: MIN_CHAR_CLASSES,
              })}
            />
            <Password
              name="password_confirmation"
              id="password_confirmation"
              autoComplete="new-password"
              validations="equalsField:password"
              required
              aria-label={props.intl.formatMessage(
                messages.passwordConfirmationLabel
              )}
              placeholder={props.intl.formatMessage(
                messages.passwordConfirmationLabel
              )}
              validationError={props.intl.formatMessage(
                passwordNotMatchingErrorMessage
              )}
            />
            <Checkbox
              name="newsletter"
              id="newsletter"
              label={props.intl.formatMessage(messages.newsletterLabel)}
            />
          </Stack>
          <FormActions key="actions" appearance="full">
            <SubmitButton
              type="submit"
              state={props.pending ? "pending" : undefined}
              appearance="primary"
            >
              <FormattedMessage
                id="modules.RegisterForm.create"
                defaultMessage="Create my account"
              />
            </SubmitButton>
          </FormActions>
          {props.additionalActions}
        </Stack>
      </Fieldset>
    </Form>
  );
};

export default compose(
  EnhanceRegisterForm({ LoginMutation, RegisterMutation }),
  injectIntl
)(RegisterForm);
