const defaultConfig = require("../../../src/config/shared/images");

const isProd = process.env.FRONT_COMMERCE_ENV === "production";

// configuration: https://account.twicpics.com/workspace/63e335b0-5f2b-11ec-9f21-4778def0f234 -> Domains -> `front-commerce.twic.pics`
// docs: http://developers.front-commerce.com/docs/advanced/images-adapters/twicpics#configuration
const createPath = (path) => {
  const prefix = isProd ? "demo/prod" : "demo/dev";
  return {
    path: `/${prefix}${path}`,
    source: path,
  };
};
defaultConfig.twicpics = {
  domain: "https://front-commerce.twic.pics",
  paths: [createPath("/media"), createPath("/images")],
};

module.exports = defaultConfig;
